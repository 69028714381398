export const projects = [
    {
        title: "Amber",
        subtitle: "Cloud Kitchen System",
        description: "Setup your cloud kitchen with Amber",
        image: "https://placeimg.com/240/240/tech",
        link: "https://amber.coffeeinc.in",
    },
    {
        title: "Nucleus",
        subtitle: "Multi-vendor eCommerce Marketplace",
        description: "Multi-vendor eCommerce Marketplace",
        image: "https://nucleus.coffeeinc.in/wp-content/uploads/2020/06/Opera-Snapshot_2020-06-14_203318_www.freepik.com_.png",
        link: "https://demo.nucleus.coffeeinc.in",
    },
    {
        title: "Strand",
        subtitle: "Workflow Management system",
        description: "Manage your entire workflow online",
        image: "https://placeimg.com/240/240/people",
        link: "https://strand.coffeeinc.in",
    },
    {
        title: "Margar",
        subtitle: "Construction Planning and Resource Management system",
        description:
            "Manage resources and documents of your construction company",
        image: "https://placeimg.com/240/240/arch",
        link: "https://margarerp.coffeeinc.in",
    },
    {
        title: "Falcon",
        subtitle: "Vehicle and delivery management system",
        description: "Manage delivery of consignments, drivers and vehicles",
        image: "https://placeimg.com/240/240/nature",
        link: "https://falcon.coffeeinc.in",
    },
];
