import React from "react";
import { projects } from "../data";

function Works() {
    return (
        <section id="works" className="text-gray-400 body-font overflow-hidden">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-10">
                    <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Coffee Works
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading relaxed text-base">
                        Check out our works at Coffee in web and mobile app
                        development below.
                    </p>
                </div>
                <div className="flex flex-wrap -m-4">
                    {projects.map((project) => (
                        <a
                            href={project.link}
                            key={project.image}
                            className="sm:w-1/2 w-100 p-4"
                        >
                            <div className="flex">
                                <div class="flex-shrink-0 sm:w-32 sm:h-32 w-24 h-24 rounded-md overflow-hidden">
                                    <img
                                        src={project.image}
                                        alt={project.description}
                                        class="w-full h-full object-center object-cover"
                                    />
                                </div>
                                <div className="mx-4 text-left m-auto">
                                    <p class="md:text-lg text-base font-semibold">
                                        {project.title}
                                    </p>
                                    <p class="text-sm">{project.subtitle}</p>
                                    <span class="text-sm text-blue-400 hover:text-indigo-500">
                                        {project.link}
                                    </span>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Works;
