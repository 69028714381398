import "./App.css";
import Works from "./components/Works";
import Navbar from "./components/Navbar";

function App() {
    return (
        <main className="text-gray-400 bg-gray-900 min-h-screen body-font">
            {/* <Navbar /> */}
            <Works />
        </main>
    );
}

export default App;
